<template>
    <div> <el-page-header  content="商品修改" @back="handleBack()" title="管理商品"/>
        <el-form :model="productForm" 
         :rules="productFormRules" 
         ref="productFormRef" 
         label-width="80px" 
          class="demo-product-Form"> 
                 <el-form-item label="商品名称" prop="productname">
                   <el-input v-model="productForm.title"/>   
                   </el-form-item> 
              <el-form-item label="商品简要介绍" prop="introduction">
                    <el-input v-model="productForm.introduction" type="textarea"/>     
                  </el-form-item> 
              <el-form-item label="商品详细介绍" prop="detail">
                    <el-input v-model="productForm.detail" type="textarea"/>     
                  </el-form-item> 
                 
              <el-form-item label="商品图片" prop="cover">
              <Upload :avatar="productForm.cover" @kerwinchange="handleChange"/>        
               </el-form-item> 
              <el-form-item>
                  <el-button type="primary" @click="submitForm()"
                  >更新商品</el-button>
                  </el-form-item>
    </el-form>
    </div>
    </template>
    <script setup> 
    import {ref,reactive,onMounted} from 'vue'
    import axios from 'axios'
    import Upload from '@/components/upload/Upload'
    import upload from '@/util/upload'
    import {useRouter,useRoute} from 'vue-router'

    const route = useRoute()
    const router = useRouter()
    const productFormRef=ref()
    const productForm = reactive({
       title:"",
       introduction:"",
       detail:"",
       cover:"",
       file: null,
    });
    const productFormRules = reactive({ 
    title: [{ required: true, message: '请输入商品名', trigger: 'blur' }  
          ],
    introduction: [
              { required: true, message: '请输入商品简要描述', trigger: 'blur' }  
          ],
  detail: [
              { required: true, message: '请输入商品详细描述', trigger: 'blur' }  
          ],

    cover: [
              { required: true, message: '请上传商品图片', trigger: 'blur' }  
            ]
    });
    
    
    //每次选择完图片之后的回调
    const handleChange = (file)=>{
     //console.log(file.raw)
    productForm.cover=URL.createObjectURL(file);
    productForm.file =file
    };
     
    const submitForm= ()=>{         
      productFormRef.value.validate(async (valid)=>{
       if(valid){
        //console.log(productForm)
         await upload("/adminapi/product/list",productForm)
         router.push(`/product-manage/productlist`)
        }
       })
    }

    //返回箭头
   const handleBack=()=>{
   router.back()
   }
   onMounted(()=>{
   getData()
  })
  
  //取当前页面数据
const getData = async ()=>{
  const res=await axios.get(`/adminapi/product/list/${route.params.id}`)
  //console.log(res.data.data[0])
  Object.assign(productForm,res.data.data[0])
}

    </script>
    
     <style lang="scss" scoped>
    .demo-product-Form{
        margin-top:50px ;
    }
    
    </style>
