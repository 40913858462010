<template>
    <div>
        <el-page-header  content="创建新闻" icon="" title="新闻管理"/>  
        <el-form 
          ref="newsFormRef" 
          :model="newsForm" 
          :rules="newsFormRules" 
          label-width="80px" 
           class="demo-ruleForm"> 
             <el-form-item label="标题" prop="title">
               <el-input v-model="newsForm.title"/>   
               </el-form-item> 
     
            <el-form-item label="内容" prop="content">
                 <editor @event="handleChange"/>
              </el-form-item> 
            
              <el-form-item label="类别" prop="category">
                <el-select v-model="newsForm.category" class="m-2" 
             placeholder="请选择" style="width:100%">
            <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
           </el-option>
           </el-select>
          </el-form-item>

          <el-form-item label="封面" prop="cover">
       <Upload :avatar="newsForm.cover" @kerwinchange="handleuploadChange"/>        
               </el-form-item> 

               <el-form-item>
        <el-button type="primary" @click="submitForm()"
         >添加新闻</el-button>
              </el-form-item>
        
        </el-form>
    </div>
 
</template>
<script setup> 
import {ref,reactive} from 'vue'
import editor from '@/components/editor/Editor'
import Upload from '@/components/upload/Upload'
import upload from '@/util/upload'
import {useRouter} from 'vue-router'
const router = useRouter()

const newsFormRef=ref()

const newsForm = reactive({
 title:"",
 content:"",
 category:2,//1最新动态，2典型案例，3通知公告
 cover:"",
 file:null,
 isPublish:0 //0未发布， 1已发布      
})

const newsFormRules = reactive({
    title:[
     {required:true,message:"请输入标题",trigger:"blur"} ], 
  content:[
      {required:true,message:"请输入内容",trigger:"blur"} ],
  category:[
      {required:true,message:"请选择分类",trigger:"blur"} ],
  cover:[
      {required:true,message:"请上传图片",trigger:"blur"} ]
     
    })
    //每次editor内容改变的回调
  const handleChange = (data)=>{
  //console.log(data)
   newsForm.content=data
}
 //新闻类别
const options=[
{   label:"美妆护肤",
    name:1
  },  
{   label:"健康商品",
    name:2
 },  
 {   label:"民宿预定",
    name:3
 },  
 {   label:"留学咨询",
    name:4
 },  
{   label:"旅行出游",
    name:5
 }    
  
]

const handleuploadChange = (file)=>{
    newsForm.cover = URL.createObjectURL(file);
    newsForm.file = file
  }

  const submitForm= ()=>{     
  newsFormRef.value.validate(async(valid)=>{
   if(valid){
    console.log(newsForm);
    //后台通讯
    await upload("/adminapi/news/add",newsForm)
    router.push(`/news-manage/newslist`)
   
    }
   })
}
</script>
<style lang="scss" scoped>
.demo-ruleForm{
    margin-top:50px ;
}

</style>