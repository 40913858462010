<template>
<el-upload

              class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="handleChange"
              >
    
               <img v-if="props.avatar" :src="uploadAvatar" class="avatar"/>
              <el-icon v-else class="avatar-uploader-icon">
                <Plus/>
              </el-icon>
              </el-upload>
</template>
<script setup> 
import {defineEmits,defineProps,computed} from 'vue'
import {Plus} from '@element-plus/icons-vue'
const props = defineProps({
    avatar:String   })//父传子

const emit = defineEmits(["kerwinchange"])//子传父

const uploadAvatar =computed(()=>
   props.avatar.includes("blob")?props.avatar:'https://www.syoy0080.com.cn/public'+props.avatar)
  // props.avatar.includes("blob")?props.avatar:'http://localhost:3000'+props.avatar)
//每次选择完图片之后的回调
const handleChange = file=>{
emit("kerwinchange",file.raw)

};

</script>
<style lang="scss" scoped>

::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .el-upload:hover {
    border-color: #409EFF;
  }
  ::v-deep.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar{
 width:178px;
 height:178px;
}
</style>