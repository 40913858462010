<template>

<el-card>
  <el-page-header  content="用户管理" icon="" title="用户列表"/>
    <el-table :data="tableData" style="width: 100%">
    <el-table-column prop="username" label="用户名" width="180" />

    <el-table-column el-table-column label="头像" width="180" >
    <template #default="scope">
      <div v-if="scope.row.avatar">
       <el-avatar :size="50" 
        :src="'https://www.syoy0080.com.cn/public/'+scope.row.avatar">
       </el-avatar>
      </div>
      <div v-else>
      <el-avatar :size="50" src="'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'" />
      </div>

      </template>
    </el-table-column>
    

    <el-table-column prop="address" label="角色" >
      <template #default="scope">
        <el-tag 
          v-if="scope.row.role===1"
          class="ml-2" type="danger">管理员</el-tag>
       <el-tag 
          v-else
          class="ml-2" type="success">编辑</el-tag>

      </template>
    </el-table-column>

    <el-table-column  label="操作" >
      <template #default="scope">
        <el-button size="small" @click="handleEdit(scope.row)"
          >编辑</el-button
        >
       
        <el-popconfirm title="你确定要删除吗?"
        confirmButtonText="确定"
         cancelButtonText="取消"
        @confirm="handleDelete(scope.row)">
        <template #reference>
      <el-button
       size="small"
       type="danger"
       >删除</el-button>
        </template>
  </el-popconfirm>

      </template>   
    </el-table-column>
  </el-table>
</el-card>

<el-dialog
    v-model="dialogVisible"
    title="编辑用户"
    width="50%"   
  >
  <el-form :model="userForm" 
     :rules="userFormRules" 
     ref="userFormRef" 
     label-width="120px" 
      class="demo-userForm"> 

             <el-form-item label="用户名" prop="username">
               <el-input v-model="userForm.username"/>   
               </el-form-item> 


             <el-form-item label="密码" prop="password" type="password">
               <el-input v-model="userForm.password"  type="password" autocomplete="off"/>   
             </el-form-item> 

             <el-form-item label="角色" prop="gender">
                <el-select v-model="userForm.role" class="m-2" 
             placeholder="请选择" style="width:100%">
            <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
           </el-option>
           </el-select>
          </el-form-item> 

          <el-form-item label="个人简介" prop="introduction">
                <el-input v-model="userForm.introduction" type="textarea"/>     
              </el-form-item> 


</el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleEditConfirm()">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  
</template>

<script  setup>

  import {ref,onMounted,reactive} from 'vue'
  import axios from 'axios'
 // import {toRefs} from 'vue'

const dialogVisible = ref(false)


const userFormRef=ref()
let userForm = reactive({
   username:"",
   password:"",
   role:2,//1:管理员 2：编辑
   introduction:""

});
const tableData =ref([]);
  onMounted(()=>{
   getTableData()
  })
const getTableData = async ()=>{
 const res = await axios.get(`/adminapi/user/list`)
 console.log(res.data)
 tableData.value = res.data.data
 
}
const userFormRules = reactive({ 
username: [{ required: true, message: '请输入名字', trigger: 'blur' }  
      ],
password: [{ required: true, message: '请输入密码', trigger: 'blur' }  
      ],
role: [ { required: true, message: '请选择权限', trigger: 'blur' }  
      ],
introduction: [
          { required: true, message: '请输入个人信息', trigger: 'blur' }  
      ]

});
//角色选择
const options = [
{label:"管理员", value:1},
{label:"编辑",value:2}];

//编辑回调
const handleEdit =async data=>{
  dialogVisible.value = true
  const res = await axios.get(`/adminapi/user/list/${data._id}`)
  console.log("handelEdit="+res.data.data) 
  //userForm.value=res.data.data[0]
  
  Object.assign(userForm,res.data.data[0])
     }
//删除记录
  const handleDelete =async data => {
  console.log(data)   
  await axios.delete(`/adminapi/user/list/${data._id}`)
  //reload page
  //reload data
  getTableData();
  //tabledata本地删除
};
//编辑确认回调
const handleEditConfirm =()=>{
  userFormRef.value.validate(async (valid)=>{
    if(valid){
      console.log("uuuu"+userForm)

      //1-更新后端
       await axios.put(`/adminapi/user/list/${userForm._id}`,
       userForm)
     //2-提示框隐藏
       dialogVisible.value = false
     //3-获取table数据
       getTableData();
        }
       })
     }


</script>
<style lang="scss" scoped>
.el-table {
     margin-top:50px;
  }
</style>