
import axios from 'axios'
function upload(path,userForm){
    const params = new FormData()
    for (let i in userForm){
     params.append(i,userForm[i])
    }
   console.log("upload-params=="+params)
  
  //如果传入数据有文件格式的，头要再说明一下
   //return axios.post("/adminapi/user/upload",params,{
    return axios.post(path,params,{
   headers:{ "Content-Type":"multipart/form-data"  }
    }).then(res=>res.data)

}
export default upload