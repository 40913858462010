<template>
    <div> <el-page-header  content="首页" icon="" title="东阳堂企业管理系统"/>
<el-card class="box-card">
<el-row>
  <el-col :span="4">
     <el-card class="box-card">
   <el-avatar :size="100" :src="avatarUrl"/>
      </el-card>
  </el-col>
  <el-col :span="20">
    <h3 style="line-height:100px;">欢迎{{store.state.userInfo.username}}回来，{{welcomText}}</h3>
  </el-col>
</el-row>

</el-card>

<el-card class="box-card">
  <template #header>
  <div slot="header" class="clearfix">
    <span>公司产品</span>  
  </div>
  </template>

  <el-carousel 
  v-if="loopList.length"
  :interval="2000" 
  indicator-position="outside"
  >

    <el-carousel-item v-for="item in loopList" :key="item._id">      
   <div :style="{
    backgroundImage:`url(https://www.syoy0080.com.cn/public/${item.cover})`,
    backgroundSize:'cover' }"> 
   
      <h3 >{{ item.title }}</h3>   
    </div>
    </el-carousel-item>

  </el-carousel>
</el-card>
</div>
</template>

<script setup> 
import {useStore} from 'vuex'
import {computed,onMounted,ref} from 'vue'
import axios from 'axios'
const loopList = ref([])


const store =useStore()
console.log("home store=="+store.state)
const avatarUrl=computed(()=>store.state.userInfo.avatar?'https://www.syoy0080.com.cn/public'+store.state.userInfo.avatar:
 //const avatarUrl=computed(()=>store.state.userInfo.avatar?'http://localhost:3000'+store.state.userInfo.avatar:
 `https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png`) 
 
  const welcomText=computed(()=>new Date().getHours()<12?`快乐工作每一天！`:`需要来杯咖啡提提神吗`)

  onMounted (()=>{
      getData() })

  const getData =async()=>{
  const res=await axios.get(`/adminapi/product/list`)
        console.log(res.data.data)
        loopList.value=res.data.data
        
    }

 </script>
<style lang="scss" scoped>
 .box-card{
    margin-top:50px;
 }
 .el-carousel__item h3 {
    color:white;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>
