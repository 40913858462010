import axios from 'axios'
// Add a request interceptor请求发出之前
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const token = localStorage.getItem("token")
    config.headers.Authorization =`Bearer ${token}` 
    return config;
  }, function (error) {
    // Do something with request error
    //console.log("request err=="+error.request)
    return Promise.reject(error);
  });

// Add a response interceptor得到响应之前
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //console.log("拦截器："+response.headers)//自己添加来测试
    
   const {authorization} =response.headers
   authorization && localStorage.setItem("token",authorization)
   //console.log("拦截器：response得到响应之前2")
                
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    //console.log("reponse err oy=="+error.response)
    const {status} =error.response
   if (status===401) {
  
    localStorage.removeItem("token")
   //window.location.href="/"
   window.location.href="/login"
}

    return Promise.reject(error);
  });