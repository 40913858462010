import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";


export default createStore({
  state: {
      isGetterRouter:false,
      isCollapsed:false,
      userInfo:{}

  },
 
  getters: {
  },
  mutations: {
      //控制侧边栏的展开
  changeCollapsed(state){
  state.isCollapsed=!state.isCollapsed
  },
  changeUserInfo(state,value){
    state.userInfo={
    ...state.userInfo,//保存原来的值
    ...value
     }
   },
   clearUserInfo(state,value){
    state.userInfo ={}
   },
    changeGetterRouter(state,value){
      state.isGetterRouter = value
       }    
  },
  actions: {
  },
  modules: {
  },
  plugins:[createPersistedState({
    paths:["isCollapsed","userInfo"]//控制是否持有化
  })]

})

