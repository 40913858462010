<template>
    <div> <el-page-header  content="商品管理" icon="" title="添加产品"/>
        <el-form :model="productForm" 
         :rules="productFormRules" 
         ref="productFormRef" 
         label-width="80px" 
          class="demo-product-Form"> 
                 <el-form-item label="产品名称" prop="productname">
                   <el-input v-model="productForm.title"/>   
                   </el-form-item> 
              <el-form-item label="商品简要介绍" prop="introduction">
                    <el-input v-model="productForm.introduction" type="textarea"/>     
                  </el-form-item> 
              <el-form-item label="商品详细介绍" prop="detail">
                    <el-input v-model="productForm.detail" type="textarea"/>     
                  </el-form-item> 
              <el-form-item label="价格" prop="price">
               <el-input v-model.number="productForm.price"></el-input>
                </el-form-item>
                <el-form-item label="库存" prop="inventory">
               <el-input v-model.number="productForm.inventory"></el-input>
                </el-form-item> 
              <el-form-item label="商品图片" prop="cover">
              <Upload :avatar="productForm.cover" @kerwinchange="handleChange"/>        
               </el-form-item> 

              <el-form-item>
                  <el-button type="primary" @click="submitForm()"
                  >添加商品</el-button>
                  </el-form-item>
    </el-form>
    </div>
    </template>
    <script setup> 
    import {ref,reactive} from 'vue'
    import Upload from '@/components/upload/Upload'
    import upload from '@/util/upload'
    import {useRouter} from 'vue-router'
    
    const router = useRouter()
    const productFormRef=ref()
    const productForm = reactive({
       title:"",
       introduction:"",
       detail:"",
       cover:"",
       price:0,
       inventory:0,
       file: null,
    });
    const productFormRules = reactive({ 
    title: [{ required: true, message: '请输入名字', trigger: 'blur' }  
          ],
    introduction: [
              { required: true, message: '请输入商品简要描述', trigger: 'blur' }  
          ],
  detail: [
              { required: true, message: '请输入商品详细描述', trigger: 'blur' }  
          ],

    cover: [
              { required: true, message: '请上传商品图片', trigger: 'blur' }  
            ]
    });
    
    
    //每次选择完图片之后的回调
    const handleChange = (file)=>{
     //console.log(file.raw)
    productForm.cover=URL.createObjectURL(file);
    productForm.file =file
    };
     
    const submitForm= ()=>{         
      productFormRef.value.validate(async (valid)=>{
       if(valid){
        console.log(productForm)
         //await upload("/adminapi/product/add",productForm)
         router.push(`/product-manage/productlist`)
        }
       })
    }

    
    </script>
    
     <style lang="scss" scoped>
    .demo-product-Form{
        margin-top:50px ;
    }
    
    </style>
